<template>
  <div class="rsvp px-2">
    <p class="text-3xl font-bold mb-8">RSVP Pre-event</p>
    <div class="mb-6">
      <TextField type="text" v-model="requiredPayload.fullName" :borderEnabled="true" placeholder="Input your full name" label="Full Name" :error="errors['fullName']" />
    </div>
    <div class="mb-6">
      <TextField type="text" v-model="requiredPayload.email" :borderEnabled="true" placeholder="Input your email address" label="Email Address" :error="errors['email']" />
    </div>
    <div class="mb-6">
      <TextField type="text" v-model="phoneNumber" :borderEnabled="true" placeholder="Input your phone number" label="Phone Number (optional)" :error="errors['phoneNumber']" />
    </div>
    <div>
      <label class="text-xs pl-3 text-left block mb-4">Choose Pre-Event to RSVP</label>
      <div class="mb-3 text-left flex items-center gap-3" v-for="(item, index) in program" :key="'program-' + index">
        <div class="leading-0">
          <input type="checkbox" :disabled="isDisabled(`${item.schedule}`, `${item.scheduleTo}`)" class="styled-checkbox" :id="'tnc-' + index" name="tnc" :value="item.id" v-model="checkedProgram" />
          <label :for="'tnc-' + index">&nbsp;</label>
        </div>
        <p>{{ item.name }}</p>
      </div>
    </div>
    <Button buttonText="RSVP" @action="rsvp()" :disabled="checkedProgram.length === 0" />
  </div>
</template>
<script>
import { isNotEmptyError } from '@/utils'
import { mapActions } from 'vuex'
import dayjs from 'dayjs'
const CONFIG = {
  email: 'Email',
  fullName: 'Full Name'
}
export default {
  components: {
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  props: {
    program: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    requiredPayload: {
      fullName: null,
      email: null
    },
    phoneNumber: null,
    checkedProgram: [],
    errors: []
  }),
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('program', ['enrollV2']),
    formatDisplayDate(date, time) {
      const formattedDate = dayjs(date).format('YYYY-MM-DD')
      return `${formattedDate}T${time && time.replace('.', ':')}:00`
    },
    isDisabled(date, time) {
      const formattedDate = this.formatDisplayDate(date, time)
      const currentTime = new Date().getTime()
      const eventEndDate = new Date(formattedDate).getTime()
      return currentTime > eventEndDate
    },
    rsvp() {
      this.errors = []
      if (this.validForm()) {
        // eslint-disable-next-line
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
        if (!regex.test(this.requiredPayload.email)) {
          this.errors['email'] = 'Email format is invalid!'
        } else {
          this.showLoading()
          const payloads = {
            customerId: 3,
            formCode: '04',
            name: this.requiredPayload.fullName,
            email: this.requiredPayload.email,
            mobilePhone: this.phoneNumber,
            programCode: '',
            socialMedia: null,
            socialMediaAccount: null,
            coreClassIds: this.checkedProgram,
            priceCategory: null
          }
          this.enrollV2({
            payloads: payloads
          }).then(() => {
            this.hideLoading()
            this.$emit('success')
          })
        }
      }
    },
    validForm() {
      const error = isNotEmptyError(this.requiredPayload)
      this.errors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      return Object.keys(this.errors).length === 0
    }
  }
}
</script>
<style lang="scss" scoped>
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    // margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #f7931e;
    border-radius: 4px;
  }

  // Box hover
  &:hover + label:before {
    background: #f7931e;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: #f7931e;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: not-allowed;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
    border-color: #ddd;
    cursor: not-allowed;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
</style>
